import React from "react";
import { ReactComponent as KokiKitchenLogo } from "../koki-kitchen-logo.svg";
import "./styles/Header.css";

function Header() {
    return (
        <div className="header-container">
            <KokiKitchenLogo id="logo" />
            <div className="menu-bar">
                
            </div>
        </div>
    );
}

export default Header;