import React from 'react'
import Header from "./Header"
import "./styles/fonts.css"
import "./styles/css-reset.css"
import "./styles/App.css"

function App() {
  return (
    <div className="app-container">
      <Header />
    </div>
  );
}

export default App;
